define("ember-simple-auth-token/authenticators/token", ["exports", "@ember/object", "@ember/application", "fetch", "rsvp", "@ember/utils", "ember-simple-auth/authenticators/base"], function (_exports, _object, _application, _fetch, _rsvp, _utils, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Authenticator that works with token-based authentication like JWT.
  
    _The factory for this authenticator is registered as `'authenticator:token'` in Ember's container._
  
    @class Token
    @namespace SimpleAuth.Authenticators
    @module ember-simple-auth-token/authenticators/token
    @extends Base
  */
  var _default = _exports.default = _base.default.extend({
    /**
      @method init
    */
    init: function init() {
      this._super.apply(this, arguments);
      var owner = (0, _application.getOwner)(this);
      var environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      var config = environment['ember-simple-auth-token'] || {};
      this.serverTokenEndpoint = config.serverTokenEndpoint || '/api/token-auth/';
      this.tokenPropertyName = config.tokenPropertyName || 'token';
      this.headers = config.headers || {};
    },
    /**
      Restores the session from a set of session properties; __will return a resolving promise when there's a non-empty `token` in the `properties`__ and a rejecting promise otherwise.
       @method restore
      @param {Object} properties Properties to restore the session from
      @return {Promise} Promise that when it resolves results in the session being authenticated
    */
    restore: function restore(properties) {
      var _this = this;
      var propertiesObject = _object.default.create(properties);
      return new _rsvp.Promise(function (resolve, reject) {
        if (!(0, _utils.isEmpty)(propertiesObject.get(_this.tokenPropertyName))) {
          return resolve(properties);
        } else {
          return reject();
        }
      });
    },
    /**
      Authenticates the session with the specified `credentials`; the credentials are `POST`ed to the [`Authenticators.Token#serverTokenEndpoint`](#SimpleAuth-Authenticators-Token-serverTokenEndpoint) and if they are valid the server returns an auth token in response. __If the credentials are valid and authentication succeeds, a promise that resolves with the server's response is returned__, otherwise a promise that rejects with the server error is returned.
       @method authenticate
      @param {Object} credentials Credentials to authenticate the session with
      @param {Object} headers Headers to send with the authentication request
      @return {Promise} Promise that resolves when an auth token is successfully acquired from the server and rejects otherwise
    */
    authenticate: function authenticate(credentials, headers) {
      return this.makeRequest(this.serverTokenEndpoint, credentials, _objectSpread(_objectSpread({}, this.headers), headers)).then(function (response) {
        return response.json;
      });
    },
    /**
      Does nothing
       @method invalidate
      @return {Promise} Resolving promise
    */
    invalidate: function invalidate() {
      return (0, _rsvp.resolve)();
    },
    /**
      @method makeRequest
      @param {String} url Server endpoint
      @param {Object} data Object that will be sent to server
      @param {Object} headers Additional headers that will be sent to server
      @return {Promise} Promise that resolves when the request is successfully and rejects otherwise
    */
    makeRequest: function makeRequest(url, data, headers) {
      return (0, _fetch.default)(url, {
        method: 'POST',
        headers: _objectSpread({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, headers),
        body: JSON.stringify(data)
      }).then(function (response) {
        var res = {
          statusText: response.statusText,
          status: response.status,
          headers: response.headers
        };
        return response.text().then(function (text) {
          res.text = text;
          try {
            res.json = JSON.parse(text);
          } catch (e) {
            return (0, _rsvp.reject)(res);
          }
          if (response.ok) {
            return res;
          } else {
            return (0, _rsvp.reject)(res);
          }
        }).catch(function () {
          return (0, _rsvp.reject)(res);
        });
      });
    }
  });
});