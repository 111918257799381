define("ember-on-modifier/helpers/prevent-default", ["exports", "@ember/application/deprecations", "@ember/component/helper", "@ember/debug"], function (_exports, _deprecations, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.preventDefault = preventDefault;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function preventDefault(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      handler = _ref2[0];
    (false && !(false) && (0, _deprecations.deprecate)('`(prevent-default)` has been moved to `ember-event-helpers`.', false, {
      id: 'ember-on-modifier.prevent-default',
      until: '1.0.0',
      url: 'https://github.com/buschtoens/ember-event-helpers'
    }));
    (false && !(!handler || typeof handler === 'function') && (0, _debug.assert)("Expected '".concat(handler, "' to be a function, if present."), !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.preventDefault === 'function') && (0, _debug.assert)("Expected '".concat(event, "' to be an Event and have a 'preventDefault' method."), event && typeof event.preventDefault === 'function'));
      event.preventDefault();
      if (handler) handler(event);
    };
  }
  var _default = _exports.default = (0, _helper.helper)(preventDefault);
});