define("ember-google-maps/components/g-map/control", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/control", "ember-google-maps/utils/options-and-events", "@ember/object", "rsvp"], function (_exports, _mapComponent, _control, _optionsAndEvents, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * @class Control
   * @namespace GMap
   * @module ember-google-maps/components/g-map/control
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _control.default,
    _type: 'control',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['index', 'class'])),
    _addComponent: function _addComponent() {
      var _elementDestination = (0, _object.set)(this, '_elementDestination', document.createElement('div'));
      var _getProperties = (0, _object.getProperties)(this, ['map', 'class', 'index']),
        map = _getProperties.map,
        classNames = _getProperties.class,
        index = _getProperties.index;
      if (classNames) {
        _elementDestination.classList.add(classNames);
      }
      if (Number.isInteger(index)) {
        _elementDestination.index = index;
      }
      var controlPosition = google.maps.ControlPosition[(0, _object.get)(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', _elementDestination));
    },
    _updateComponent: function _updateComponent() {}
  });
});