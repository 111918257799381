define("@fortawesome/ember-fontawesome/components/fa-icon", ["exports", "@ember/component", "@fortawesome/ember-fontawesome/templates/components/fa-icon", "@fortawesome/fontawesome-svg-core", "@ember/template", "@ember/object", "@ember/polyfills", "ember-get-config", "@ember/debug"], function (_exports, _component, _faIcon, _fontawesomeSvgCore, _template, _object, _polyfills, _emberGetConfig, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /*eslint no-console: ["error", { allow: ["warn"] }] */
  function getWithDefault(object, key, defaultValue) {
    var value = (0, _object.get)(object, key);
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  }
  function getConfigOption(key, defaultValue) {
    return getWithDefault(_emberGetConfig.default, "fontawesome.".concat(key), defaultValue);
  }
  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? _defineProperty({}, key, value) : {};
  }
  function classList() {
    var classes = _defineProperty(_defineProperty(_defineProperty({
      'fa-spin': this.spin,
      'fa-pulse': this.pulse,
      'fa-fw': this.fixedWidth,
      'fa-border': this.border,
      'fa-li': this.listItem,
      'fa-flip-horizontal': this.flip === 'horizontal' || this.flip === 'both',
      'fa-flip-vertical': this.flip === 'vertical' || this.flip === 'both'
    }, "fa-".concat(this.size), getWithDefault(this, 'size', null) !== null), "fa-rotate-".concat(this.rotation), getWithDefault(this, 'rotation', null) !== null), "fa-pull-".concat(this.pull), getWithDefault(this, 'pull', null) !== null);
    return Object.keys(classes).map(function (key) {
      return classes[key] ? key : null;
    }).filter(function (key) {
      return key;
    });
  }
  function normalizeIconArgs(prefix, icon) {
    var defaultPrefix = getConfigOption('defaultPrefix', 'fas');
    if (!icon) {
      return {
        prefix: defaultPrefix,
        iconName: null
      };
    }
    if (_typeof(icon) === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }
    if (typeof prefix === 'string' && typeof icon === 'string') {
      return {
        prefix: prefix,
        iconName: icon
      };
    }
    if (typeof icon === 'string') {
      return {
        prefix: defaultPrefix,
        iconName: icon
      };
    }
  }
  var IconComponent = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (this.params && this.params.length) {
        (false && !(false) && (0, _debug.deprecate)('Passing the icon as a position param is deprecated and will be removed in v1.0.0.', false, {
          id: '@fortawesome/ember-fontawesome.no-positional-params',
          until: '1.0.0',
          url: 'https://github.com/FortAwesome/ember-fontawesome#template'
        }));
      }
    },
    layout: _faIcon.default,
    tagName: 'svg',
    classNameBindings: ['allClasses'],
    attributeBindings: [
    // attributes watched for mutation
    'data-prefix', 'data-icon', 'data-fa-transform', 'data-fa-mask', 'data-fa-processed',
    // accessibility attributes
    'aria-hidden', 'aria-labelledby', 'focusable',
    // svg attributes
    'role', 'xmlns', 'viewBox', 'safeStyle:style'],
    html: (0, _object.computed)('abstractIcon.children.[]', function () {
      var abstractIcon = this.abstractIcon;
      var newHtml;
      if (!abstractIcon) {
        newHtml = (0, _template.htmlSafe)('');
      } else {
        newHtml = (0, _template.htmlSafe)(abstractIcon.children.reduce(function (acc, cur) {
          return "".concat(acc).concat((0, _fontawesomeSvgCore.toHtml)(cur));
        }, ''));
      }
      return newHtml;
    }),
    safeStyle: (0, _object.computed)('attributes', function () {
      var attributes = this.attributes;
      var style = getWithDefault(attributes, 'style');
      return style ? (0, _template.htmlSafe)("".concat(style)) : undefined;
    }),
    iconOrPositionalParam: (0, _object.computed)('icon', 'params.[]', function () {
      if (this.icon) {
        return this.icon;
      }
      if (this.params && this.params.length) {
        return this.params[0];
      }
      return null;
    }),
    abstractIcon: (0, _object.computed)('border', 'fixedWidth', 'flip', 'icon', 'iconOrPositionalParam', 'listItem', 'mask', 'prefix', 'pull', 'pulse', 'rotation', 'size', 'spin', 'symbol', 'title', 'transform', function () {
      var iconLookup = normalizeIconArgs(this.prefix, this.iconOrPositionalParam);
      var classes = objectWithKey('classes', _toConsumableArray(classList.bind(this)()));
      var transformProp = this.transform;
      var transform = objectWithKey('transform', typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp);
      var mask = objectWithKey('mask', normalizeIconArgs(null, this.mask));
      var symbol = getWithDefault(this, 'symbol', false);
      var title = getWithDefault(this, 'title', null);
      if (title) {
        title = "".concat(title);
      }
      var o = (0, _polyfills.assign)({}, classes, transform, mask, {
        symbol: symbol,
        title: title
      });
      var renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);
      if (!renderedIcon) {
        console.warn("Could not find icon: iconName=".concat(iconLookup.iconName, ", prefix=").concat(iconLookup.prefix));
        return null;
      }
      return renderedIcon.abstract[0];
    }),
    attributes: (0, _object.computed)('abstractIcon.attributes', function () {
      var abstractIcon = this.abstractIcon;
      return abstractIcon ? abstractIcon.attributes : {};
    }),
    allClasses: (0, _object.computed)('abstractIcon', 'attributes.class', 'class', function () {
      var abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return _fontawesomeSvgCore.config.replacementClass;
      }
      var attributes = this.attributes;
      var iconClasses = getWithDefault(attributes, 'class');
      return iconClasses;
    }),
    'data-prefix': (0, _object.computed)('attributes.data-prefix', function () {
      return getWithDefault(this.attributes, 'data-prefix');
    }),
    'data-icon': (0, _object.computed)('attributes.data-icon', function () {
      return getWithDefault(this.attributes, 'data-icon');
    }),
    'data-fa-transform': (0, _object.computed)('attributes.data-fa-transform', function () {
      return getWithDefault(this.attributes, 'data-fa-transform');
    }),
    'data-fa-mask': (0, _object.computed)('attributes.data-fa-mask', function () {
      return getWithDefault(this.attributes, 'data-fa-mask');
    }),
    'data-fa-processed': (0, _object.computed)('attributes.data-fa-processed', function () {
      return getWithDefault(this.attributes, 'data-fa-processed');
    }),
    'aria-hidden': (0, _object.computed)('attributes.aria-hidden', function () {
      return getWithDefault(this.attributes, 'aria-hidden');
    }),
    'aria-labelledby': (0, _object.computed)('attributes.aria-labelledby', function () {
      return getWithDefault(this.attributes, 'aria-labelledby');
    }),
    'focusable': (0, _object.computed)('attributes.focusable', function () {
      return getWithDefault(this.attributes, 'focusable');
    }),
    'role': (0, _object.computed)('attributes.role', function () {
      return getWithDefault(this.attributes, 'role');
    }),
    'xmlns': (0, _object.computed)('attributes.xmlns', function () {
      return getWithDefault(this.attributes, 'xmlns');
    }),
    'viewBox': (0, _object.computed)('abstractIcon', 'attributes.viewBox', function () {
      var abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return '0 0 448 512';
      }
      return getWithDefault(this.attributes, 'viewBox');
    })
  });

  // Enables {{fa-icon 'iconnamehere'}} syntax, while still allowing {{fa-icon icon='iconnamehere'}}
  IconComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = _exports.default = IconComponent;
});