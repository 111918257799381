function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers", "@ember/object", "rsvp"], function (_exports, _mapComponent, _infoWindow, _optionsAndEvents, _helpers, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InfoWindowAPI = InfoWindowAPI;
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function InfoWindowAPI(source) {
    var mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      actions: {
        open: function open() {
          return source.open();
        },
        close: function close() {
          return source.close();
        }
      }
    });
  }

  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    isOpen: false,
    _cachedIsOpen: false,
    position: (0, _object.computed)('lat', 'lng', _helpers.position),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['isOpen', 'target', 'content'])),
    _createOptions: function _createOptions(options) {
      var newOptions = {
        content: undefined
      };
      if (!(0, _object.get)(this, 'target')) {
        newOptions.position = (0, _object.get)(this, 'position');
      }
      if ((0, _object.get)(this, 'isOpen')) {
        newOptions.content = this._getContent();
      }
      return _objectSpread(_objectSpread({}, options), newOptions);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.publicAPI = InfoWindowAPI(this);
    },
    _addComponent: function _addComponent(options) {
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', new google.maps.InfoWindow(options)));
    },
    _didAddComponent: function _didAddComponent() {
      this._openOrClose();
      this._super.apply(this, arguments);
    },
    _updateComponent: function _updateComponent(mapComponent, options) {
      mapComponent.setOptions(options);
      this._openOrClose();
    },
    _openOrClose: function _openOrClose() {
      var isOpen = (0, _object.get)(this, 'isOpen');
      var isOpenChanged = this._cachedIsOpen !== isOpen;
      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }
      (0, _object.set)(this, '_cachedIsOpen', isOpen);
    },
    _getContent: function _getContent() {
      if (this.content) {
        return this.content;
      }
      var content = document.createElement('div');
      (0, _object.set)(this, '_targetPane', content);
      (0, _object.set)(this, 'content', content);
      return content;
    },
    open: function open() {
      var _this = this;
      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', function () {
          (0, _object.set)(_this, 'isOpen', false);
        });
        this.mapComponent.open((0, _object.get)(this, 'map'), (0, _object.get)(this, 'target'));
      }
    },
    close: function close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }
  });
});